/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input, ModalHeader, ModalBody, ModalFooter, Button, Select, SelectItem, Checkbox } from '@nextui-org/react';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { GeneralService, ModeratorsService } from '../../../services';
import { sanitizeToNumbers } from '../../../utils';

const ModalCreate = ({ user, onSuccess, onClose }) => {
  const { documentTypes, form, genders, onChange, onSubmit, parishes, permissions, phoneCodes, states, togglePermission, townships } = useForm(user);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const submit = async () => {
    const isSuccess = await onSubmit(form);
    if (isSuccess) {
      onClose();
      onSuccess();
    }
  }

  return (
    <>
      <ModalHeader className="flex flex-col gap-1">{ user ? 'Editar' : 'Nuevo' } Moderador</ModalHeader>
      <ModalBody className="grid grid-cols-2">
        <Input
          label="Nombre"
          labelPlacement="outside"
          classNames={{ inputWrapper: 'border-1' }}
          variant="bordered"
          value={form.name}
          onValueChange={v => onChange(v, 'name')}
        />
        <Input
          label="Apellido"
          labelPlacement="outside"
          variant="bordered"
          classNames={{ inputWrapper: 'border-1' }}
          value={form.last_name}
          onValueChange={v => onChange(v, 'last_name')}
        />
        <Select
          label="Sexo"
          labelPlacement="outside"
          variant="bordered"
          classNames={{ trigger: 'border-1' }}
          disallowEmptySelection={true}
          selectedKeys={!!genders.length && !!form.gender_id ? [form.gender_id] : []}
          onSelectionChange={v => onChange(v.currentKey, 'gender_id')}
        >
          {genders.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              { item.label }
            </SelectItem>
          ))}
        </Select>
        <Input
          label="Fecha de nacimiento"
          labelPlacement="outside"
          variant="bordered"
          type="date"
          classNames={{ inputWrapper: 'border-1' }}
          value={form.birthdate}
          onValueChange={v => onChange(v, 'birthdate')}
        />
        <div className="flex">
          <Select
            label="Cédula"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{ base: 'w-1/3', trigger: 'border-1 border-r-0 rounded-tr-none rounded-br-none' }}
            disallowEmptySelection={true}
            selectedKeys={!!documentTypes.length && !!form.dniType ? [form.dniType] : []}
            onSelectionChange={v => onChange(v.currentKey, 'dniType')}
          >
            {documentTypes.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Input
            label=" "
            labelPlacement="outside"
            variant="bordered"
            classNames={{ base: 'w-2/3', inputWrapper: 'border-1 border-l-0 rounded-tl-none rounded-bl-none' }}
            value={form.dni}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'dni')}
          />
        </div>
        <div className="flex">
          <Select
            label="Teléfono"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{ base: 'w-1/3', trigger: 'border-1 border-r-0 rounded-tr-none rounded-br-none' }}
            disallowEmptySelection={true}
            selectedKeys={!!phoneCodes.length && !!form.phoneCode ? [form.phoneCode] : []}
            onSelectionChange={v => onChange(v.currentKey, 'phoneCode')}
          >
            {phoneCodes.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Input
            label=" "
            labelPlacement="outside"
            variant="bordered"
            classNames={{ base: 'w-2/3', inputWrapper: 'border-1 border-l-0 rounded-tl-none rounded-bl-none' }}
            value={form.phone}
            onValueChange={v => onChange(sanitizeToNumbers(v), 'phone')}
          />
        </div>
        <Input
          label="Correo electrónico"
          labelPlacement="outside"
          variant="bordered"
          classNames={{ inputWrapper: 'border-1' }}
          value={form.email}
          onValueChange={v => onChange(v, 'email')}
        />
        <Input
          label="Dirección"
          labelPlacement="outside"
          variant="bordered"
          classNames={{ inputWrapper: 'border-1' }}
          value={form.address}
          onValueChange={v => onChange(v, 'address')}
        />
        <div className="flex col-span-2 gap-3">
          <Select
            label="Estado"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{ base: 'w-1/3', trigger: 'border-1' }}
            disallowEmptySelection={true}
            selectedKeys={!!states.length && !!form.state_id ? [form.state_id] : []}
            onSelectionChange={v => onChange(v.currentKey, 'state_id')}
          >
            {states.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Municipio"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{ base: 'w-1/3', trigger: 'border-1' }}
            disallowEmptySelection={true}
            selectedKeys={!!townships.length && !!form.township_id ? [form.township_id] : []}
            onSelectionChange={v => onChange(v.currentKey, 'township_id')}
          >
            {townships.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
          <Select
            label="Parroquia"
            labelPlacement="outside"
            variant="bordered"
            placeholder=" "
            classNames={{ base: 'w-1/3', trigger: 'border-1' }}
            disallowEmptySelection={true}
            selectedKeys={!!parishes.length && !!form.parish_id ? [form.parish_id] : []}
            onSelectionChange={v => onChange(v.currentKey, 'parish_id')}
          >
            {parishes.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                { item.label }
              </SelectItem>
            ))}
          </Select>
        </div>
        <Input
          label="Contraseña"
          labelPlacement="outside"
          variant="bordered"
          type={isVisible ? "text" : "password"}
          classNames={{ inputWrapper: 'border-1' }}
          value={form.password}
          onValueChange={v => onChange(v, 'password')}
          endContent={
            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
              {isVisible
                ? <IconEyeOff className="w-6 h-6" />
                : <IconEye className="w-6 h-6" />
              }
            </button>
          }
        />
        <Input
          label="Repetir contraseña"
          labelPlacement="outside"
          variant="bordered"
          type={isVisible ? "text" : "password"}
          classNames={{ inputWrapper: 'border-1' }}
          value={form.password_confirmation}
          onValueChange={v => onChange(v, 'password_confirmation')}
          endContent={
            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
              {isVisible
                ? <IconEyeOff className="w-6 h-6" />
                : <IconEye className="w-6 h-6" />
              }
            </button>
          }
        />

        <h2 className="text-lg font-semibold col-span-2">Perfiles</h2>
        {permissions.map(item => {
          const isChecked = form.permissions.includes(item.value);
          return (
            <Checkbox key={item.value} isSelected={isChecked} onClick={() => togglePermission(item.value)}>
              { item.label }
            </Checkbox>
          )
        })}
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
        <Button color="primary" onPress={submit}>Guardar</Button>
      </ModalFooter>
    </>
  )
}

const useForm = (user) => {
  const initialForm = {
    id: user?.id ?? '',
    level_id: user?.level_id ?? '',
    email: user?.email ?? '',
    name: user?.name ?? '',
    last_name: user?.last_name ?? '',
    dni: user?.dni ? String(user.dni).substring(1) : '',
    phone: user?.phone ? String(user.phone).substring(4) : '',
    gender_id: user?.gender_id ? String(user.gender_id) : null,
    dniType: user?.dni ? String(user.dni).substring(0,1) : '',
    phoneCode: user?.phone ? String(user.phone).substring(0,4) : '',
    birthdate: user?.birthdate ?? '',
    address: user?.address ?? '',
    state_id: user?.state_id ? String(user?.state_id) : null,
    township_id: user?.township_id ? String(user?.township_id) : null,
    parish_id: user?.parish_id ? String(user?.parish_id) : null,
    permissions: user?.permissions?.map(x => x.permission_id) ?? [],
    password: '',
    password_confirmation: '',
  };

  const [form, setForm] = useState(initialForm);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [states, setStates] = useState([]);
  const [townships, setTownships] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [permissions, setPermissions] = useState([]);

	const getSelects = async () => {
		Promise.all([
			GeneralService.getDocumentTypes(),
			GeneralService.getGenders(),
			GeneralService.getStates(),
			GeneralService.getPhoneCodes(),
			GeneralService.getPermissions(),
		])
			.then(res => {
        setDocumentTypes(res[0] ?? []);
        setGenders(res[1].map(x => ({ value: x.id, label: x.name })) ?? []);
        setStates(res[2].map(x => ({ value: x.id, label: x.name })) ?? []);
        setPhoneCodes(res[3] ?? []);
        setPermissions(res[4].map(x => ({ value: x.id, label: x.name })) ?? []);
			})
			.catch(console.log);
	}

	const getTownships = async (state_id) => {
		if (!state_id) return;
		GeneralService.getTownships({ state_id })
			.then(res => {
				setTownships(res.map(x => ({ value: x.id, label: x.name })));
			})
			.catch(console.log);
	}

	const getParishes = async (township_id) => {
		if (!township_id) return;
		GeneralService.getParishes({ township_id })
			.then(res => {
				setParishes(res.map(x => ({ value: x.id, label: x.name })));
			})
			.catch(console.log);
	}

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));

    if (target === 'state_id') {
      if (value) getTownships(value);
      onChange(null, 'township_id');
    }
    if (target === 'township_id') {
      if (value) getParishes(value);
      onChange(null, 'parish_id');
    }
  }

  const togglePermission = (itemId) => {
    if (form.permissions.includes(itemId)) {
      const others = form.permissions.filter(pId => pId !== itemId);
      setForm({ ...form, permissions: others });

    } else {
      setForm({ ...form, permissions: [ ...form.permissions, itemId ] });
    }
  }

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const isValidForm = () => {
    if (!form.name)
      return onError('El nombre es obligatorio');

    if (!form.last_name)
      return onError('El apellido es obligatorio');

    if (!form.gender_id)
      return onError('El sexo es obligatorio');

    if (!form.dniType)
      return onError('El tipo de documento es obligatorio');

    if (!form.dni)
      return onError('El número de documento es obligatorio');

    if (!form.phoneCode)
      return onError('El código de área es obligatorio');

    if (!form.phone)
      return onError('El teléfono es obligatorio');

    if (!form.birthdate)
      return onError('La fecha de nacimiento es obligatoria');

    if (!form.address)
      return onError('La dirección es obligatoria');

    if (!form.email)
      return onError('El correo es obligatorio');

    if (!form.state_id)
      return onError('El estado es obligatorio');

    if (!form.township_id)
      return onError('El municipio es obligatorio');

    if (!form.parish_id)
      return onError('La parroquia es obligatoria');

    if (!user && !form.password)
      return onError('La contraseña es obligatoria');

    if (!user && form.password !== form.password_confirmation)
      return onError('Las contraseñas no coinciden');

    return true;
  }

  const onSubmit = async () => {
    if (!isValidForm()) return;
    try {
      !!user
        ? await ModeratorsService.admin.update(form)
        : await ModeratorsService.admin.create(form);
      toast.success(`Moderador ${ user ? 'actualizado' : 'creado'} correctamente`);
      return true;

    } catch (error) {
      onError(String(error));
      return false;
    }
  }

  useEffect(() => {
    getSelects();
    if (user) {
      getTownships(user.state_id);
      getParishes(user.township_id);
    }
  }, []);

  return {
    documentTypes,
    form,
    genders,
    onChange,
    onSubmit,
    parishes,
    permissions,
    phoneCodes,
    states,
    togglePermission,
    townships,
  }
}

export default ModalCreate;
