import { fetchWrapper, objectToQueryParams } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'general';

export const findPaymentFrequencies = async (filters) => {
  const url = `${baseUrl}/payment-frequencies?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const createConversion = async (form) => {
  const url = `${baseUrl}/conversion`;
  return await fetchWrapper.post(url, form);
}

export const findConversion = async (filters) => {
  const url = `${baseUrl}/conversion?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const getDocumentTypes = async () => {
  const url = `${baseUrl}/document-types`;
  return await fetchWrapper.get(url);
}

export const getGenders = async () => {
  const url = `${baseUrl}/genders`;
  return await fetchWrapper.get(url);
}

export const getPhoneCodes = async () => {
  const url = `${baseUrl}/phone-codes`;
  return await fetchWrapper.get(url);
}

export const getStates = async () => {
  const url = `${baseUrl}/states`;
  return await fetchWrapper.get(url);
}

export const getTownships = async (filters) => {
  const url = `${baseUrl}/townships?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const getParishes = async (filters) => {
  const url = `${baseUrl}/parishes?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export const getPermissions = async () => {
  const url = `${baseUrl}/permissions`;
  return await fetchWrapper.get(url);
}