/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { GeneralService } from '../services';
import { AuthContext } from '../context';
import { formatAmount } from '../utils';

const Conversion = () => {
  const { conversion, form, isLoading, onChange, submit } = useForm();
  return (
    <ModalContent>
      {(onClose) => (
        <>
          <ModalHeader className="flex flex-col gap-1">Tasa de Cambio</ModalHeader>
          <ModalBody className="my-4">
            <Input
              placeholder={'Bs. ' + formatAmount(conversion || 0)}
              variant="bordered"
              value={form.amount}
              onValueChange={v => onChange(v, 'amount')}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="light" isDisabled={isLoading} onPress={onClose}>
              Cancelar
            </Button>
            <Button
              color="primary"
              isDisabled={isLoading}
              isLoading={isLoading}
              onPress={async () => {
                const isSuccess = await submit();
                if (isSuccess) onClose();
              }}
            >
              Guardar
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  );
}

const useForm = () => {
  const INITIAL_FORM = {
    amount: '',
  };

  const { conversion, setConversion } = useContext(AuthContext);
  const [canFetch, setCanFetch] = useState(true);
  const [form, setForm] = useState({ ...INITIAL_FORM });

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const getConversion = async () => {
    try {
      const response = await GeneralService.findConversion();
      if (response[0].amount !== conversion) {
        setConversion(response[0].amount);
      }

    } catch (error) {
      onError(String(error));
    }
  }

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    if (!form.amount)
      return onError('El monto es obligatorio');

    if (parseFloat(form.amount) === conversion)
      return onError('El monto debe ser distinto a la tasa actual');

    return true;
  }

  const submit = async () => {
    if (!canFetch || !isValidForm()) return;
    setCanFetch(false);

    try {
      const response = await GeneralService.createConversion({ amount: parseFloat(form.amount) });
      setConversion(response.amount);
      toast.success(`Tasa de cambio actualizada con éxito`);
      setCanFetch(true);
      return true;

    } catch (error) {
      onError(String(error));
      setCanFetch(true);
      return false;
    }
  }

  useEffect(() => {
    getConversion();
  }, []);

  return {
    conversion,
    form,
    isLoading: !canFetch,
    onChange,
    submit,
  };
}

export default Conversion;
