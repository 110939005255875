/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Chip, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconEdit, IconPlayerPause, IconPlayerPlay, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Constants, formatAmount } from '../../../utils';
import { ProductsService } from '../../../services';
import CreateProduct from './create-product';

const MODAL_ACTION = {
  NONE: 0,
  CREATE: 1,
  VIEW: 2,
  EDIT: 3,
  DELETE: 4,
  TURN_OFF: 5,
  TURN_ON: 6,
}

const modalSM = [
  MODAL_ACTION.DELETE,
  MODAL_ACTION.TURN_OFF,
  MODAL_ACTION.TURN_ON,
];

const modalXL = [
  MODAL_ACTION.CREATE,
  MODAL_ACTION.VIEW,
  MODAL_ACTION.EDIT,
];

const getStatusColor = (status) => {
  if (status === Constants.PRODUCT.STATUS.ACTIVE) return 'primary';
  if (status === Constants.PRODUCT.STATUS.INACTIVE) return 'warning';
  return 'default';
}

const Products = () => {
  const nav = useNavigate();
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, filterBy, goToPage, isLoading, pagination, reload, deleteItem, updateItem } = useFetchTable();

  const onSelectItem = (item, action) => {
    setSelectedItem(item);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="sm"
        isOpen={modalSM.includes(modalAction)}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.DELETE) return (
              <ModalDelete
                onClose={onClose}
                onDelete={()=> {
                  deleteItem(selectedItem?.id);
                  closeModal();
                }}
              />
            );
            if (modalAction === MODAL_ACTION.TURN_ON) return (
              <ModalTurn
                type={MODAL_ACTION.TURN_ON}
                onClose={onClose}
                onTurn={()=> {
                  updateItem(selectedItem?.id, Constants.PRODUCT.STATUS.ACTIVE);
                  closeModal();
                }}
              />
            );
            if (modalAction === MODAL_ACTION.TURN_OFF) return (
              <ModalTurn
                type={MODAL_ACTION.TURN_OFF}
                onClose={onClose}
                onTurn={()=> {
                  updateItem(selectedItem?.id, Constants.PRODUCT.STATUS.INACTIVE);
                  closeModal();
                }}
              />
            );
          }}
        </ModalContent>
      </Modal>

      <Modal
        size="3xl"
        isOpen={modalXL.includes(modalAction)}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.CREATE) return (
              <CreateProduct onClose={onClose} onSuccess={() => closeModal(true)} />
            );
            if (modalAction === MODAL_ACTION.EDIT) return (
              <CreateProduct product={selectedItem} onClose={onClose} onSuccess={() => closeModal(true)} />
            );
          }}
        </ModalContent>
      </Modal>

      <Filters
        services={[]}
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table
        aria-label="Pagos registrados"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primary">Productos registrados</h3>
            <Button
              color="primary"
              className="pl-2"
              startContent={<IconPlus color="white" />}
              onClick={() => nav('/productos/nuevo')}
            >
              Nuevo
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>Nombre</TableColumn>
          <TableColumn>
            <div className="flex flex-col">
              <span>Nombre </span>
              <sub>Precio 1</sub>
            </div>
          </TableColumn>
          <TableColumn>Precio 1 ($)</TableColumn>
          <TableColumn>
            <div className="flex flex-col">
              <span>Nombre </span>
              <sub>Precio 2</sub>
            </div>
          </TableColumn>
          <TableColumn>Precio 2 ($)</TableColumn>
          <TableColumn>
            <div className="flex flex-col">
              <span>Nombre </span>
              <sub>Precio 3</sub>
            </div>
          </TableColumn>
          <TableColumn>Precio 3 ($)</TableColumn>
          <TableColumn>Estatus</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={data} emptyContent="No hay productos">
          {(product) => {

            const getPrice = (position) => {
              return product?.is_deductible_based
                ? product?.deductibles?.length >= position + 1 ? product?.deductibles[position] : null
                : product?.prices?.length >= position + 1 ? product.prices[position] : null;
            }
            const minPrice = (item) => {
              if (!product?.is_deductible_based)
                return !!parseFloat(item.price_monthly) ? item.price_monthly : item.price_annual;

              // Devuelve el precio anual de la primera columna y primera fila si cumple las condiciones
              // Selecciona todos los precios de la columna 1 que estén habilitados y sea mayor a cero
              const notEmptyOrDisabled = item?.premiums
                ?.filter(p => p.col_order === 1 && !p.is_disabled && p.price_annual > 0)
                ?.sort((a,b) => a.row_order - b.row_order) || [];
              const prices = notEmptyOrDisabled.map(x => x.price_annual);

              return !!prices.length ? prices[0] : 0;
            }

            const price1 = getPrice(0);
            const price2 = getPrice(1);
            const price3 = getPrice(2);
            const statusColor = getStatusColor(product?.status);

            return (
              <TableRow key={product.id}>
                <TableCell>{ product.name }</TableCell>
                <TableCell>{ price1?.name }</TableCell>
                <TableCell>{ price1?.name ? formatAmount(minPrice(price1),'$') : '' }</TableCell>
                <TableCell>{ price2?.name }</TableCell>
                <TableCell>{ price2?.name ? formatAmount(minPrice(price2),'$') : '' }</TableCell>
                <TableCell>{ price3?.name }</TableCell>
                <TableCell>{ price3?.name ? formatAmount(minPrice(price3),'$') : '' }</TableCell>
                <TableCell>
                  <Chip variant="solid" color={statusColor}>
                    { product?.status_text }
                  </Chip>
                </TableCell>
                <TableCell align="right">
                  <div className="relative flex justify-end items-center gap-2">
                    {product?.status === Constants.PRODUCT.STATUS.ACTIVE && (
                      <Tooltip color="warning" content="Suspender">
                        <IconPlayerPause onClick={() => onSelectItem(product, MODAL_ACTION.TURN_OFF)} />
                      </Tooltip>
                    )}
                    {product?.status === Constants.PRODUCT.STATUS.INACTIVE && (
                      <Tooltip content="Activar">
                        <IconPlayerPlay onClick={() => onSelectItem(product, MODAL_ACTION.TURN_ON)} />
                      </Tooltip>
                    )}
                    <Tooltip content="Editar">
                      <IconEdit onClick={() => nav(`/productos/${product.id}/editar`)} />
                    </Tooltip>
                    <Tooltip color="danger" content="Eliminar">
                      <IconTrash onClick={() => onSelectItem(product, MODAL_ACTION.DELETE)} />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const ModalDelete = ({ onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Estás seguro de eliminar este producto?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="danger" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const ModalTurn = ({ type, onClose, onTurn }) => {
  const action = type === MODAL_ACTION.TURN_OFF
    ? 'suspender'
    : 'activar';

  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Estás seguro de { action } este producto?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="danger" onPress={onTurn}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = { search: '' };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre del producto"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    userType: Constants.USER.LEVELS.OWNER.toString(),
    search: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await ProductsService.admin.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const deleteItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await ProductsService.admin.destroy(id);
      toast.success('Producto eliminado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateItem = async (id, status) => {
    if (!canFetch) return;
    setCanFetch(false);

    const form = { id, status };

    try {
      await ProductsService.admin.setStatus(form);
      toast.success('Producto actualizado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    data,
    deleteItem,
    updateItem,
    filters,
    filterBy,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
  }
}

export default Products;
