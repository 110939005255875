/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Modal } from '@nextui-org/react';
import { IconCategory2, IconChevronDown, IconCreditCard, IconFirstAidKit, IconHome, IconMenu2, IconShield, IconUsers, IconWallet, IconUserShield } from '@tabler/icons-react';
import { AuthContext } from '../context';
import { CroppedLogo, Logo } from '../assets/images';
import { classNames, Constants } from '../utils';
import Conversion from './conversion';

const Layout = () => {
  const { conversion, user } = useContext(AuthContext);
  const location = useLocation();
  const nav = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [navigation, setNavigation] = useState([]);
  const routeName = navigation.find(x => {
    const currentParts = location.pathname.split('/');
    const pathParts = x.href.split('/');

    if (currentParts.length !== pathParts.length) return false;

    return pathParts.every((item, i) => {
      const _current = currentParts[i];
      return pathParts[i].includes(':') ? !isNaN(+_current) : item === _current;
    });
  })?.name || '';

  const toggleSidebar = () => {
    const items = document.getElementsByClassName('desk-sidebar');
    for (let key = 0; key < items.length; key++) {
      items.item(key).classList.toggle('open');
    }
  }

  const getUserMenu = () => {
    const perm = Constants.PERMISSIONS;
    // No está loggeado
    if (!user || ![Constants.USER.LEVELS.ADMIN, Constants.USER.LEVELS.MODERATOR].includes(user.level_id))
      return;

    const MENU = [
      {
        id: 1,
        name: 'Cambiar contraseña',
        href: '/profile',
        icon: IconHome,
        hidden: true,
      },
      {
        id: 2,
        name: 'Inicio',
        href: '/',
        icon: IconHome,
      },
      {
        id: 3,
        name: 'Moderadores',
        href: '/moderadores',
        icon: IconUserShield,
        profile: [
          perm.ADMINISTRATOR,
        ],
      },
      {
        id: 4,
        name: 'Usuarios',
        href: '/usuarios',
        icon: IconUsers,
        profile: [
          perm.ADMINISTRATOR,
          perm.SUBSCRIPTION,
        ],
      },
      {
        id: 5,
        name: 'Productos',
        href: '/productos',
        icon: IconShield,
        profile: [
          perm.ADMINISTRATOR,
          perm.SUBSCRIPTION,
        ],
      },
      {
        id: 6,
        name: 'Servicios',
        href: '/servicios',
        icon: IconCategory2,
        profile: [
          perm.ADMINISTRATOR,
          perm.SERVICES,
        ],
      },
      {
        id: 7,
        name: 'Gestión de Servicios',
        href: '/solicitudes',
        icon: IconFirstAidKit,
        profile: [
          perm.ADMINISTRATOR,
          perm.SERVICES,
        ],
      },
      {
        id: 8,
        name: 'Gestión de Pagos',
        href: '/pagos',
        icon: IconWallet,
        profile: [
          perm.ADMINISTRATION,
          perm.ADMINISTRATOR,
          perm.SUBSCRIPTION,
        ],
      },
      {
        id: 9,
        name: 'Gestión de Suscripción',
        href: '/suscripciones',
        icon: IconCreditCard,
        profile: [
          perm.ADMINISTRATOR,
          perm.SUBSCRIPTION,
        ],
      },
      {
        id: 10,
        name: 'Crear Producto',
        href: '/productos/nuevo',
        icon: IconHome,
        hidden: true,
      },
      {
        id: 11,
        name: 'Editar Producto',
        href: '/productos/:id/editar',
        icon: IconHome,
        hidden: true,
      },
    ];

    // Es un moderador
    if (user.level_id === Constants.USER.LEVELS.MODERATOR) {
      const userPermissions = user?.permissions?.map(x => x.permission_id);
      const filteredMenu = MENU.filter(item => {
        const isForAllUsers = !item.profile;
        const hasPermission = item.profile?.some(profile => userPermissions.includes(profile));
        return isForAllUsers || hasPermission;
      });
      return setNavigation(filteredMenu);
    }

    // Es un administrador
    setNavigation(MENU);
  }

  useEffect(() => {
    getUserMenu();
  }, []);

  return (
    <div id="layout">

      <Modal
        size="sm"
        backdrop="blur"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <Conversion />
      </Modal>

      <div className="desk-sidebar desk-sidebar__header">
        <div className="sidebar-logo">
          <div className="sidebar-logo__wrapper">
            <img className="sidebar-logo__cropped" src={CroppedLogo} alt={process.env.REACT_APP_NAME} />
            <img className="sidebar-logo__normal" src={Logo} alt={process.env.REACT_APP_NAME} />
          </div>
        </div>
        <div className="desk-headerbar">
          <button type="button" className="-mr-2.5 p-2.5 text-gray-700" onClick={toggleSidebar}>
            <span className="sr-only">Open sidebar</span>
            <IconMenu2 />
          </button>

          <div className="h-6 w-px bg-gray-200" aria-hidden="true" />

          <span className="hidden md:block font-medium text-lg">{ routeName }</span>

          <div className="flex flex-1 gap-x-4 self-stretch items-center lg:gap-x-6">
            <div className="relative flex flex-1" />

            <Button variant="bordered" disableAnimation onClick={() => setShowModal(true)}>
              <span className="inline-flex items-center text-primary text-sm font-semibold">
                Bs. { conversion } = $ 1,00
              </span>
            </Button>

            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

              <div className="relative">
                <Dropdown placement="bottom-start">
                  <DropdownTrigger disabled={false}>
                    <Button variant="bordered">
                      <span className="font-semibold leading-6 text-gray-900" aria-hidden="true">
                        { user?.name }
                      </span>
                      <IconChevronDown className="ml-2 text-gray-400" size={18} />
                    </Button>
                  </DropdownTrigger>

                  <DropdownMenu aria-label="User Actions" variant="flat">
                    <DropdownItem onClick={() => nav('/profile')}>
                      Cambiar contraseña
                    </DropdownItem>
                    <DropdownItem color="danger" onClick={() => nav('/logout')}>
                      Cerrar sesión
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SideBar navigation={navigation} />

      <main>
        {!!user && <Outlet />}
      </main>
    </div>
  )
}

const SideBar = ({ navigation }) => {
  const location = useLocation();

  return (
    <div className="desk-sidebar">
      <aside>
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col -mx-2 space-y-1">
              {navigation.filter(x => !x.hidden).map((item, index) => {
                const isActive = location.pathname === item.href;
                return (
                  <li key={index} className="menu-item" title={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        isActive
                          ? 'text-primary active'
                          : 'text-gray-700 hover:text-primary',
                        'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'
                      )}
                    >
                      <item.icon className={classNames('aspect-square shrink-0', isActive ? 'text-white':'text-gray-500 group-hover:text-primary')} />
                      <span>{item.name}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  )
}

export default Layout;