/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useId, useState } from 'react';
import { Button, Tooltip, User } from '@nextui-org/react';
import { IconDownload, IconFile, IconPdf, IconUpload } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { Constants, fromPhotos } from '../../../utils';

const PopoverDocuments = ({ orderId, user, docs, onSubmit }) => {
  const [form, setForm] = useState({
    id: orderId,
    dniFile: user.dni_file || '',
    dniFileUrl: user.dni_file ? fromPhotos(user.dni_file) : '',
    fiscalDniFile: user.fiscal_dni_file || '',
    fiscalDniFileUrl: user.fiscal_dni_file ? fromPhotos(user.fiscal_dni_file) : '',
    orderFile: '',
    orderFileUrl: '',
    initialOrderFile: '',
    orderDocs: [],
    hasFile: true,
  });

  const onChange = (value, target) => {
    if (!value) return;
    if (value.type !== 'application/pdf' && !String(value.type).startsWith('image/'))
      return toast.error('Sólo puedes subir una foto o PDF');

    setForm(s => ({
      ...s,
      [target]: value,
      [target + 'Url']: !!value ? URL.createObjectURL(value) : '',
    }));
  }

  const onChangeOrder = (value, target, index) => {
    if (!value) return;
    if (value.type !== 'application/pdf' && !String(value.type).startsWith('image/'))
      return toast.error('Sólo puedes subir una foto o PDF');

    const orders = form.orderDocs;
    orders[index][target] = value;
    orders[index].url = !!value ? URL.createObjectURL(value) : '';

    setForm(s => ({ ...s, orderDocs: orders }));
  }

  const canSubmitForm = () => {
    const isDirtyDni = form.dniFile !== (user.dni_file || '');
    const isDirtyFiscalDni = form.fiscalDniFile !== (user.fiscal_dni_file || '');
    const isDirtyOrder = form.orderFile !== form.initialOrderFile;
    const isDirtySomeDoc = form.orderDocs?.some(x => x.file !== x.initial);
    return isDirtyDni || isDirtyFiscalDni || isDirtyOrder || isDirtySomeDoc;
  }

  const canSubmit = canSubmitForm();

  useEffect(() => {
    const files = {};
    const orderDocs = [];

    docs?.forEach(doc => {
      if (doc.file_id === Constants.ORDER_DOC.FILENAME.ORDER) {
        files.orderFile = doc.url;
        files.orderFileUrl = fromPhotos(doc.url);
        files.initialOrderFile = doc.url;
      } else {
        orderDocs.push({
          ...doc,
          file: doc.url,
          initial: doc.url,
          url: fromPhotos(doc.url),
        });
      }
    });

    const currentFiletypes = docs?.map(doc => doc.file_id) || [];
    const emptyFile = {
      order_id: orderId,
      file: '',
      initial: '',
      url: '',
    };

    if (!currentFiletypes?.includes(Constants.ORDER_DOC.FILENAME.DNI))
      orderDocs.push({
        ...emptyFile,
        file_id: Constants.ORDER_DOC.FILENAME.DNI,
        file_name: 'Cédula de Identidad',
      });

    if (!currentFiletypes?.includes(Constants.ORDER_DOC.FILENAME.MEDICAL_REPORT))
      orderDocs.push({
        ...emptyFile,
        file_id: Constants.ORDER_DOC.FILENAME.MEDICAL_REPORT,
        file_name: 'Informe Médico',
      });

    if (!currentFiletypes?.includes(Constants.ORDER_DOC.FILENAME.PRESCRIPTION))
      orderDocs.push({
        ...emptyFile,
        file_id: Constants.ORDER_DOC.FILENAME.PRESCRIPTION,
        file_name: 'Récipe',
      });

    setForm(s => ({ ...s, ...files, orderDocs }));
  }, []);

  return (
    <div className="py-2 space-y-3 max-w-xs">
      <ItemUpload
        label="Cédula de Identidad"
        src={form.dniFileUrl}
        isPDF={String(form.dniFile?.type ?? form.dniFile ?? '').endsWith('pdf')}
        canDownload={user.dni_file}
        onChange={file => onChange(file, 'dniFile')}
      />
      <ItemUpload
        label="RIF"
        src={form.fiscalDniFileUrl}
        isPDF={String(form.fiscalDniFile?.type ?? form.fiscalDniFile ?? '').endsWith('pdf')}
        canDownload={user.fiscal_dni_file}
        onChange={file => onChange(file, 'fiscalDniFile')}
      />

      {form.orderDocs.map((doc, index) => {
        const file = doc.file;
        return (
          <ItemUpload
            key={index}
            label={doc?.file_name}
            src={doc.url}
            isPDF={String(file?.type ?? file ?? '').endsWith('pdf')}
            canDownload={doc.initial}
            onChange={f => onChangeOrder(f, 'file', index)}
          />
        )
      })}

      <ItemUpload
        label="Orden"
        src={form.orderFileUrl}
        isPDF={String(form.orderFile?.type ?? form.orderFile ?? '').endsWith('pdf')}
        canDownload={form.initialOrderFile}
        onChange={file => onChange(file, 'orderFile')}
      />

      <Button
        radius="sm"
        fullWidth
        color={canSubmit ? 'primary':'default'}
        isDisabled={!canSubmit}
        onClick={() => onSubmit(form)}
      >
        Guardar
      </Button>
    </div>
  )
}

const ItemUpload = ({ label, src, onChange, canDownload, canUpload = true, isPDF }) => {
  const inputId = useId();

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = fromPhotos(canDownload);
    link.download = true;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  return (
    <div className="flex items-center justify-between gap-x-4">
      {!src && (
        <User
          avatarProps={{ radius: 'sm', fallback: <IconFile /> }}
          name={label}
        />
      )}
      {(!!src && !isPDF) && (
        <User avatarProps={{ src, radius: 'sm' }} name={label} />
      )}
      {(!!src && isPDF) && (
        <User
          avatarProps={{ radius: 'sm', fallback: <IconPdf /> }}
          name={label}
        />
      )}
      <input
        id={inputId}
        type="file"
        hidden
        onChange={e => {
          onChange(e.target.files[0]);
          e.target.value = '';
        }}
      />
      <div className="flex items-center gap-4">
        {!!canDownload && (
          <Tooltip content="Descargar">
            <IconDownload className="cursor-pointer text-primary" onClick={downloadFile} />
          </Tooltip>
        )}
        {!!canUpload && (
          <Tooltip content="Subir foto o documento">
            <label htmlFor={inputId}>
              <IconUpload className="cursor-pointer text-primary" onClick={() => {}} />
            </label>
          </Tooltip>
        )}
      </div>
    </div>
  )
}


export default PopoverDocuments;