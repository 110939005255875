import { useState } from 'react';
import { Button, Radio, RadioGroup } from '@nextui-org/react';
import { Constants } from '../../../utils';

const medicStatuses = [
  { value: Constants.ORDER.MEDIC_STATUS.PENDING.toString(), label: 'Pendiente' },
  { value: Constants.ORDER.MEDIC_STATUS.APPROVED.toString(), label: 'Aprobado' },
  { value: Constants.ORDER.MEDIC_STATUS.REJECTED.toString(), label: 'Rechazado' },
];

const PopMedicStatus = ({ order, onSubmit }) => {
  const [form, setForm] = useState({
    id: order.id,
    medic_status: String(order.medic_status),
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const canSubmit = form.medic_status !== (order.medic_status || '');

  return (
    <div className="py-2 space-y-4">
      <RadioGroup
        label="Selecciona el estatus médico"
        value={form.medic_status}
        onValueChange={v => onChange(v, 'medic_status')}
      >
        {medicStatuses.map(item => (
          <Radio value={item.value}>{ item.label }</Radio>
        ))}
      </RadioGroup>

      <Button
        radius="sm"
        fullWidth
        color={canSubmit ? 'primary':'default'}
        isDisabled={!canSubmit}
        onClick={() => onSubmit(form)}
      >
        Guardar
      </Button>
    </div>
  )
}

export default PopMedicStatus;