/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconEdit, IconSearch, IconTrash } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, fromPhotos, fromStorage } from '../../../utils';
import { UsersService } from '../../../services';
import ModalEdit from './edit-user';

const MODAL_ACTION = {
  NONE: 0,
  VIEW: 1,
  EDIT: 2,
  DELETE: 3,
}

const level_ids = [
  { value: Constants.USER.LEVELS.OWNER.toString(), label: 'Titular' },
  { value: Constants.USER.LEVELS.BENEFICIARY.toString(), label: 'Beneficiario' },
];

const Users = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, filters, filterBy, goToPage, isLoading, pagination, reload, deleteItem, print } = useFetchTable();

  const onSelectItem = (item, action) => {
    setSelectedItem(item);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = fromPhotos(file);
    link.download = true;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="sm"
        isOpen={!!selectedItem}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.VIEW) return (
              <ModalView user={selectedItem} onClose={onClose} />
            );
            if (modalAction === MODAL_ACTION.EDIT) return (
              <ModalEdit
                user={selectedItem}
                onClose={onClose}
                onSuccess={() => closeModal(true)}
              />
            );
            if (modalAction === MODAL_ACTION.DELETE) return (
              <ModalDelete
                user={selectedItem}
                onClose={onClose}
                onDelete={()=> {
                  deleteItem(selectedItem?.id);
                  closeModal();
                }}
              />
            );
          }}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      {+filters?.level_id === Constants.USER.LEVELS.OWNER ? (
        <Table aria-label="Usuarios registrados"
          topContent={
            <div className="flex flex-row justify-between items-center gap-4">
              <h3 className="text-xl font-medium text-primary">Usuarios registrados</h3>
              <Button variant="bordered" onClick={() => print()}>
                Exportar
              </Button>
            </div>
          }
          topContentPlacement="inside"
        >
          <TableHeader>
            <TableColumn>Fecha de registro</TableColumn>
            <TableColumn>Nombre y apellido</TableColumn>
            <TableColumn>Sexo</TableColumn>
            <TableColumn>Cédula</TableColumn>
            <TableColumn>Teléfono</TableColumn>
            <TableColumn>Fecha de nacimiento</TableColumn>
            <TableColumn>Correo</TableColumn>
            <TableColumn>Beneficiarios</TableColumn>
            <TableColumn>Documentos</TableColumn>
            <TableColumn align="end" />
          </TableHeader>
          <TableBody
            items={data}
            emptyContent="No hay resultados"
          >
            {(user) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>{ moment(user.created_at).format('DD/MM/YYYY') }</TableCell>
                  <TableCell>{ user.fullName }</TableCell>
                  <TableCell>{ user.gender?.name }</TableCell>
                  <TableCell>{ user.dni }</TableCell>
                  <TableCell>{ user.phone }</TableCell>
                  <TableCell>{ moment(user.birthdate).format('DD/MM/YYYY') }</TableCell>
                  <TableCell>{ user.email }</TableCell>
                  <TableCell>
                    <span className="mr-2">{ user.beneficiaries?.length ?? 0 }</span>
                    {user.beneficiaries?.length > 0 && (
                      <span
                        className="font-semibold text-primary cursor-pointer"
                        onClick={() => onSelectItem(user, MODAL_ACTION.VIEW)}
                      >
                        Ver
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="flex gap-4">
                    {!!user?.dni_file && (
                      <span
                        className="font-semibold text-primary cursor-pointer"
                        onClick={() => downloadFile(user.dni_file)}
                      >
                        Cédula
                      </span>
                    )}
                    {!!user?.fiscal_dni_file && (
                      <span
                        className="font-semibold text-primary cursor-pointer"
                        onClick={() => downloadFile(user.fiscal_dni_file)}
                      >
                        RIF
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <div className="relative flex justify-end items-center gap-2">
                      <Tooltip content="Editar">
                        <IconEdit onClick={() => onSelectItem(user, MODAL_ACTION.EDIT)} />
                      </Tooltip>
                      <Tooltip color="danger" content="Eliminar">
                        <IconTrash onClick={() => onSelectItem(user, MODAL_ACTION.DELETE)} />
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )
            }}
          </TableBody>
        </Table>
      ) : (
        <Table aria-label="Usuarios registrados"
          topContent={
            <div className="flex flex-row justify-between items-center gap-4">
              <h3 className="text-xl font-medium text-primaryDark">Usuarios registrados</h3>
            </div>
          }
          topContentPlacement="inside"
        >
          <TableHeader>
            <TableColumn>Fecha de registro</TableColumn>
            <TableColumn>Nombre y apellido</TableColumn>
            <TableColumn>Sexo</TableColumn>
            <TableColumn>Cédula</TableColumn>
            <TableColumn>Teléfono</TableColumn>
            <TableColumn>Fecha de nacimiento</TableColumn>
            <TableColumn>Parentesco</TableColumn>
            <TableColumn>Correo</TableColumn>
            <TableColumn>Titular</TableColumn>
            <TableColumn align="end" />
          </TableHeader>
          <TableBody
            items={data}
            emptyContent="No hay resultados"
          >
            {(user) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>{ moment(user.created_at).format('DD/MM/YYYY') }</TableCell>
                  <TableCell>{ user.fullName }</TableCell>
                  <TableCell>{ user.gender?.name }</TableCell>
                  <TableCell>{ user.dni }</TableCell>
                  <TableCell>{ user.phone }</TableCell>
                  <TableCell>{ moment(user.birthdate).format('DD/MM/YYYY') }</TableCell>
                  <TableCell>{ user?.relationship?.name }</TableCell>
                  <TableCell>{ user.email }</TableCell>
                  <TableCell>{ user?.owner?.fullName }</TableCell>
                  <TableCell align="right">
                    <div className="relative flex justify-end items-center gap-2">
                      <Tooltip content="Editar">
                        <IconEdit onClick={() => onSelectItem(user, MODAL_ACTION.EDIT)} />
                      </Tooltip>
                      <Tooltip color="danger" content="Eliminar">
                        <IconTrash onClick={() => onSelectItem(user, MODAL_ACTION.DELETE)} />
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )
            }}
          </TableBody>
        </Table>
      )}

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    level_id: Constants.USER.LEVELS.OWNER.toString(),
    search: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 grid sm:flex grid-cols-2 lg:flex-row items-end gap-4">
      <Select
        label="Tipo de usuario"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        classNames={{ base: 'w-full sm:max-w-[10rem]', trigger: 'border-1' }}
        disallowEmptySelection={true}
        selectedKeys={[form.level_id]}
        onSelectionChange={v => onChange(v.currentKey, 'level_id')}
      >
        {level_ids.map((level_id) => (
          <SelectItem key={level_id.value} value={level_id.value}>
            { level_id.label }
          </SelectItem>
        ))}
      </Select>
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder={`Nombre del ${+form.level_id === Constants.USER.LEVELS.OWNER ? 'titular':'beneficiario'}`}
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full md:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalView = ({ user, onClose }) => {
  return (
    <>
      <ModalHeader>
        <h1>Beneficiarios</h1>
      </ModalHeader>
      <ModalBody className="items-start py-0">
        <div className="w-full">
          <ol className="list-decimal pl-4">
            {user?.beneficiaries.map((benef, index) => (
              <li key={index} className="text-md mt-2">
                <span className="font-medium">{ benef.fullName } </span>({ benef?.relationship?.name })
              </li>
            ))}
          </ol>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light"  onPress={onClose}>Cerrar</Button>
      </ModalFooter>
    </>
  )
}

const ModalDelete = ({ user, onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p className="text-center">¿Estás seguro de eliminar este usuario?</p>
        <p className="text-center font-medium">{ user.fullName }</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="danger" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    level_id: Constants.USER.LEVELS.OWNER.toString(),
    search: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await UsersService.admin.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const filtersWithDownload = {
        ...filters,
        download: 1, // Agregamos el campo download con valor 1
      };

      // Pasar el nuevo objeto filters con el campo download al servicio
      const res = await UsersService.admin.findAll(filtersWithDownload);

      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Usuarios';
      el.target = '_blank';
      el.click();
      el.remove();
    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  };

  const deleteItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await UsersService.admin.destroy(id);
      toast.success('Usuario eliminado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateItem = async (user) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await UsersService.admin.update(user);
      toast.success('Usuario actualizado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    deleteItem,
    filters,
    filterBy,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
    updateItem,
    print,
  }
}

export default Users;
