import { fetchWrapper, objectToQueryParams } from '../utils';

export class admin {
  static baseUrl = process.env.REACT_APP_BASE_API + 'admin/orders';

  static async findAll(filters) {
    const url = `${this.baseUrl}?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async download(filters) {
    const url = `${this.baseUrl}/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async update(form) {
    return await fetchWrapper.patch(this.baseUrl, form);
  }

  static async updateAuthCode(form) {
    const url = `${this.baseUrl}/auth-code`;
    return await fetchWrapper.patch(url, form);
  }

  static async updateMedicStatus(form) {
    const url = `${this.baseUrl}/medic-status`;
    return await fetchWrapper.patch(url, form);
  }

  static async updateDocuments(form) {
    const url = `${this.baseUrl}/documents`;
    return await fetchWrapper.patch(url, form);
  }
}
