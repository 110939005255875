import React, { useContext, useState } from 'react';
import { Button, Input } from '@nextui-org/react';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { AuthService, GeneralService } from '../../services';
import { Logo } from '../../assets/images';
import { AuthContext } from '../../context/auth.context';

const Login = () => {

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const { form, isLoading, onChange, onSubmit } = useForm();

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-32 w-auto"
          src={Logo}
          alt={process.env.REACT_APP_NAME}
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Ingresa a tu cuenta
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={() => onSubmit()}>
          <div className="text-sm font-medium leading-6 flex flex-col gap-4">
            <Input
              label="Correo"
              labelPlacement="outside"
              placeholder="Ingresa tu correo"
              type="email"
              variant="bordered"
              radius="sm"
              value={form.email}
              onValueChange={v => onChange(v, 'email')}
            />

            <Input
              label="Contraseña"
              labelPlacement="outside"
              placeholder="Ingresa tu contraseña"
              variant="bordered"
              radius="sm"
              type={isVisible ? "text" : "password"}
              value={form.password}
              onValueChange={v => onChange(v, 'password')}
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <IconEyeOff className="w-6 h-6" />
                  ) : (
                    <IconEye className="w-6 h-6" />
                  )}
                </button>
              }
            />
          </div>

          <Button type="submit" color="primary" radius="sm" fullWidth isLoading={isLoading} onClick={onSubmit}>
            Ingresar
          </Button>
        </form>
      </div>
    </div>
  )
}

const useForm = () => {
  const { login, setConversion } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form, setForm] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!form.email)
      return onError('Debe ingresar el correo');

    if (!form.password)
      return onError('Debe ingresar la contraseña');

    return true;
  }

  const submit = async (event) => {
    event.preventDefault();
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      const session = await AuthService.login(form);
      login(session.user?.user);
      const response = await GeneralService.findConversion();
      setConversion(response[0].amount);
      navigate('/');

    } catch (error) {
      toast.error(error || 'Ocurrió un error inesperado')
    }
    setIsLoading(false);
  }

  return {
    form,
    isLoading,
    isValidForm,
    onChange,
    onSubmit: submit,
  };
}

export default Login;