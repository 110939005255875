/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Input, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { UsersService } from '../../../services';
import { Constants } from '../../../utils';

const ModalEdit = ({ user, onSuccess, onClose }) => {
  const { form, onChange, onSubmit } = useForm(user);

  const submit = async () => {
    const isSuccess = await onSubmit(form);
    if (isSuccess) onSuccess();
  }

  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Editar Usuario</ModalHeader>
      <ModalBody>
        <Input
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Nombre"
          variant="bordered"
          value={form.name}
          onValueChange={v => onChange(v, 'name')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Apellido"
          variant="bordered"
          value={form.last_name}
          onValueChange={v => onChange(v, 'last_name')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Cédula"
          variant="bordered"
          value={form.dni}
          onValueChange={v => onChange(v, 'dni')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Teléfono"
          variant="bordered"
          value={form.phone}
          onValueChange={v => onChange(v, 'phone')}
        />
        <Input
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Correo electrónico"
          variant="bordered"
          value={form.email}
          onValueChange={v => onChange(v, 'email')}
        />
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
        <Button color="primary" onPress={submit}>Guardar</Button>
      </ModalFooter>
    </>
  )
}

const useForm = (user) => {
  const initialForm = {
    id: user?.id ?? '',
    level_id: user?.level_id ?? '',
    email: user?.email ?? '',
    name: user?.name ?? '',
    last_name: user?.last_name ?? '',
    dni: user?.dni ?? '',
    phone: user?.phone ?? '',
  };

  const [form, setForm] = useState(initialForm);

  const onChange = (value, target) => {
    setForm(s => ({
      ...s,
      [target]: value,
    }));
  }

  const onError = (msg) => {
    toast.error(msg);
    return false;
  }

  const isValidForm = (user) => {
    if (!user.name)
      return onError('El name es obligatorio');

    if (!user.last_name)
      return onError('El apellido es obligatorio');

    if (user.level_id === Constants.USER.LEVELS.OWNER) {
      if (!user.dni)
        return onError('El documento de identidad es obligatorio');

      if (!user.email)
        return onError('El correo es obligatorio');
    }

    return true;
  }

  const onSubmit = async (user) => {
    if (!isValidForm(user)) return;
    try {
      await UsersService.admin.update(user);
      toast.success('Usuario actualizado correctamente');
      return true;

    } catch (error) {
      onError(String(error));
      return false;
    }
  }

  return {form, onChange, onSubmit }
}

export default ModalEdit;
