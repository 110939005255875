/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useId, useState } from 'react';
import { Button, Tooltip, User } from '@nextui-org/react';
import { IconDownload, IconFile, IconPdf, IconUpload } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { fromPhotos } from '../../../utils';

const PopoverDocuments = ({ user, docs, products, onSubmit }) => {
  const [form, setForm] = useState({
    id: user.id,
    dniFile: user.dni_file || '',
    dniFileUrl: user.dni_file ? fromPhotos(user.dni_file) : '',
    fiscalDniFile: user.fiscal_dni_file || '',
    fiscalDniFileUrl: user.fiscal_dni_file ? fromPhotos(user.fiscal_dni_file) : '',
    hasFile: true,
  });

  const onChange = (value, target) => {
    if (!value) return;
    if (value.type !== 'application/pdf' && !String(value.type).startsWith('image/'))
      return toast.error('Sólo puedes subir una foto o PDF');

    const url = String(target).startsWith('requiredDocs_')
      ? String(target).replace('requiredDocs_','requiredDocsUrl_')
      : target + 'Url';

    setForm(s => ({
      ...s,
      [target]: value,
      [url]: !!value ? URL.createObjectURL(value) : '',
    }));
  }

  const canSubmitForm = () => {
    const isDirtyDni = form.dniFile !== (user.dni_file || '');
    const isDirtyFiscalDni = form.fiscalDniFile !== (user.fiscal_dni_file || '');
    const isDirtySomeDoc = docs?.some(x => {
      return form[`requiredDocs_${x.id}`] !== form[`initialRequiredDocs_${x.id}`];
    });
    return isDirtyDni || isDirtyFiscalDni || isDirtySomeDoc;
  }

  const uniqueProductsIds = [ ...new Set(docs.map(x => x.product_req_doc.product_id)) ];
  const groupedDocs = uniqueProductsIds.map(prodId => ({
    label: products.find(x => x.product.id === prodId)?.product?.name,
    docs: docs.filter(x => x.product_req_doc.product_id === prodId),
  }));

  const canSubmit = canSubmitForm();

  useEffect(() => {
    const files = {};

    docs?.forEach(doc => {
      files[`requiredDocs_${doc.id}`] = doc.url;
      files[`initialRequiredDocs_${doc.id}`] = doc.url;
      files[`requiredDocsUrl_${doc.id}`] = fromPhotos(doc.url);
    });

    setForm(s => ({ ...s, ...files }));
  }, []);

  return (
    <div className="py-2 space-y-3 max-w-xs">
      <ItemUpload
        label="Cédula de Identidad"
        src={form.dniFileUrl}
        isPDF={String(form.dniFile?.type ?? form.dniFile ?? '').endsWith('pdf')}
        canDownload={user.dni_file}
        onChange={file => onChange(file, 'dniFile')}
      />
      {/* <ItemUpload
        label="RIF"
        src={form.fiscalDniFileUrl}
        isPDF={String(form.fiscalDniFile?.type ?? form.fiscalDniFile ?? '').endsWith('pdf')}
        canDownload={user.fiscal_dni_file}
        onChange={file => onChange(file, 'fiscalDniFile')}
      /> */}

      {groupedDocs.map((group, index) => (
        <div key={index} className="space-y-3">
          <label className="font-semibold">{ group.label }</label>
          {group.docs.map(doc => {
            const file = form[`requiredDocs_${doc.id}`];
            const initial = form[`initialRequiredDocs_${doc.id}`];
            const url = form[`requiredDocsUrl_${doc.id}`];

            return (
              <ItemUpload
                key={doc.id}
                label={doc?.product_req_doc?.name}
                src={url}
                isPDF={String(file?.type ?? file ?? '').endsWith('pdf')}
                canDownload={initial}
                onChange={f => onChange(f, `requiredDocs_${doc.id}`)}
              />
            )
          })}
        </div>
      ))}

      <Button
        radius="sm"
        fullWidth
        color={canSubmit ? 'primary':'default'}
        isDisabled={!canSubmit}
        onClick={() => onSubmit(form)}
      >
        Guardar
      </Button>
    </div>
  )
}

const ItemUpload = ({ label, src, onChange, canDownload, isPDF }) => {
  const inputId = useId();

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = fromPhotos(canDownload);
    link.download = true;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  return (
    <div className="flex items-center justify-between gap-x-4">
      {!src && (
        <User
          avatarProps={{ radius: 'sm', fallback: <IconFile /> }}
          name={label}
        />
      )}
      {(!!src && !isPDF) && (
        <User avatarProps={{ src, radius: 'sm' }} name={label} />
      )}
      {(!!src && isPDF) && (
        <User
          avatarProps={{ radius: 'sm', fallback: <IconPdf /> }}
          name={label}
        />
      )}
      <input
        id={inputId}
        type="file"
        hidden
        onChange={e => {
          onChange(e.target.files[0]);
          e.target.value = '';
        }}
      />
      <div className="flex items-center gap-4">
        {canDownload && (
          <Tooltip content="Descargar">
            <IconDownload className="cursor-pointer text-primary" onClick={downloadFile} />
          </Tooltip>
        )}
        <Tooltip content="Subir foto o documento">
          <label htmlFor={inputId}>
            <IconUpload className="cursor-pointer text-primary" onClick={() => {}} />
          </label>
        </Tooltip>
      </div>
    </div>
  )
}


export default PopoverDocuments;