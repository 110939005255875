/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconEdit, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants } from '../../../utils';
import { ServicesService } from '../../../services';

const MODAL_ACTION = {
  NONE: 0,
  CREATE: 1,
  VIEW: 2,
  EDIT: 3,
  DELETE: 4,
}

const Services = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, createItem, data, deleteItem, filterBy, goToPage, isLoading, pagination, reload, updateItem } = useFetchTable();

  const onSelectItem = (item, action) => {
    setSelectedItem(item);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="sm"
        isOpen={modalAction !== MODAL_ACTION.NONE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.CREATE) return (
              <ModalCreate onClose={onClose} onSubmit={createItem} />
            );
            if (modalAction === MODAL_ACTION.EDIT) return (
              <ModalCreate service={selectedItem} onClose={onClose} onSubmit={updateItem} />
            );
            if (modalAction === MODAL_ACTION.DELETE) return (
              <ModalDelete
                service={selectedItem}
                onClose={onClose}
                onDelete={() => {
                  deleteItem(selectedItem?.id);
                  onClose();
                }}
              />
            );
          }}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table
        aria-label="Servicios solicitados"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primary">Servicios</h3>
            <Button
              color="primary"
              className="pl-2"
              startContent={<IconPlus color="white" />}
              onClick={() => onSelectItem(null, MODAL_ACTION.CREATE)}
            >
              Nuevo
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>N°</TableColumn>
          <TableColumn>Nombre</TableColumn>
          <TableColumn>Fecha</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(service) => (
            <TableRow key={service.id}>
              <TableCell>{ String(service.id).padStart(4,'0') }</TableCell>
              <TableCell>{ service.name }</TableCell>
              <TableCell>{ moment(service.created_at).format('DD/MM/YYYY') }</TableCell>
              <TableCell align="right">
                <div className="relative flex justify-end items-center gap-2">
                  <Tooltip content="Editar">
                    <IconEdit onClick={() => onSelectItem(service, MODAL_ACTION.EDIT)} />
                  </Tooltip>
                  <Tooltip color="danger" content="Eliminar">
                    <IconTrash onClick={() => onSelectItem(service, MODAL_ACTION.DELETE)} />
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    search: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre del servicio"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalCreate = ({ service, onClose, onSubmit }) => {
  const [form, setForm] = useState({
    id: service?.id ?? undefined,
    name: service?.name ?? '',
    apply_call: service?.apply_call ?? false,
    instructions: service?.instructions ?? '',
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const submit = async () => {
    if (!form.name) return toast.error('Debe indicar el nombre del servicio');
    onSubmit(form);
    onClose();
  }

  return (
    <>
      <ModalHeader>
        <h1>{!!service ? 'Editar':'Crear'} Servicio</h1>
      </ModalHeader>
      <ModalBody className="py-0">
        <Input
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Nombre"
          variant="bordered"
          value={form.name}
          onValueChange={v => onChange(v, 'name')}
        />

        <Divider className="my-2" />

        {/* <div className="flex flex-1 items-center justify-between">
          <h1 className="flex-1 font-semibold text-primary">
            ¿Aplica llamada telefónica?
          </h1>

          <div>
            <Tooltip content="¿Aplica llamada telefónica?" classNames={{ content: 'text-primary font-medium' }}>
              <Button
                variant={form.apply_call ? 'solid':'bordered'}
                color={form.apply_call ? 'primary':'default'}
                isIconOnly
                onClick={() => onChange(+!form.apply_call, 'apply_call')}
              >
                { form.apply_call ? 'Sí':'No' }
              </Button>
            </Tooltip>
          </div>
        </div>

        <Textarea
          classNames={{ inputWrapper: 'border-1 h-12' }}
          label="Instrucciones"
          variant="bordered"
          value={form.instructions}
          onValueChange={v => onChange(v, 'instructions')}
        /> */}
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="primary" onPress={submit}>Guardar</Button>
      </ModalFooter>
    </>
  )
}

const ModalDelete = ({ service, onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p className="text-center">¿Estás seguro de eliminar este servicio?</p>
        <p className="text-center font-medium">{ service.name }</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="danger" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    level_id: Constants.USER.LEVELS.OWNER.toString(),
    search: '',
    status: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await ServicesService.admin.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const createItem = async (form) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await ServicesService.admin.create(form);
      toast.success('Servicio creado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateItem = async (form) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await ServicesService.admin.update(form);
      toast.success('Servicio actualizado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const deleteItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await ServicesService.admin.destroy(id);
      toast.success('Servicio eliminado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    createItem,
    data,
    deleteItem,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
    updateItem,
  }
}

export default Services;
