import { fetchWrapper, objectToQueryParams } from '../utils';

export class admin {
  static baseUrl = process.env.REACT_APP_BASE_API + 'admin/subscriptions';

  static async findAll(filters) {
    const url = `${this.baseUrl}?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }

  static async updateDocuments(form) {
    const url = `${this.baseUrl}/documents`;
    return await fetchWrapper.patch(url, form);
  }

  static async updateStatus(form) {
    const url = `${this.baseUrl}/status`;
    return await fetchWrapper.patch(url, form);
  }

  static async downloadAll(filters) {
    const url = `${this.baseUrl}/download?${objectToQueryParams(filters)}`;
    return await fetchWrapper.get(url);
  }
}
