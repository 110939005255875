import { useState } from 'react';
import { Button, Checkbox, Input } from '@nextui-org/react';

const PopAuthCode = ({ order, onSubmit }) => {
  const [form, setForm] = useState({
    id: order.id,
    auth_code: order.auth_code || '',
    show_auth_code: order.show_auth_code || false,
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const canSubmit = form.auth_code !== (order.auth_code || '') || form.show_auth_code !== Boolean(order.show_auth_code);

  return (
    <div className="py-2 space-y-2">
      <Input
        variant="bordered"
        radius="sm"
        label="# Autorización"
        labelPlacement="outside"
        placeholder=" "
        classNames={{ label: 'text-small font-semibold' }}
        value={form.auth_code}
        onValueChange={v => onChange(v, 'auth_code')}
      />

      <Checkbox
        isSelected={Boolean(form.show_auth_code)}
        onValueChange={() => onChange(!form.show_auth_code, 'show_auth_code')}
      >
        <span className="text-small font-semibold">Mostrar en App</span>
      </Checkbox>

      <Button
        radius="sm"
        fullWidth
        color={canSubmit ? 'primary':'default'}
        isDisabled={!canSubmit}
        onClick={() => onSubmit(form)}
      >
        Guardar
      </Button>
    </div>
  )
}

export default PopAuthCode;