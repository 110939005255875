/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Chip, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Popover, PopoverContent, PopoverTrigger, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconEdit, IconSearch, IconThumbDown, IconThumbUp } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Constants, fromStorage } from '../../../utils';
import { OrdersService, ProductsService } from '../../../services';
import PopAuthCode from './popover-auth-code';
import PopoverDocuments from './popover-documents';
import PopMedicStatus from './popover-medic-status';

const MODAL_ACTION = {
  NONE: 0,
  APPROVE: 1,
  REJECT: 2,
}

const level_ids = [
  { value: '', label: 'Todos' },
  { value: Constants.USER.LEVELS.OWNER.toString(), label: 'Titular' },
  { value: Constants.USER.LEVELS.BENEFICIARY.toString(), label: 'Beneficiario' },
];

const serviceStatuses = [
  { value: Constants.ORDER.STATUS.PENDING.toString(), label: 'Pendiente' },
  { value: Constants.ORDER.STATUS.APPROVED.toString(), label: 'Aprobado' },
  { value: Constants.ORDER.STATUS.REJECTED.toString(), label: 'No procedente' },
];

function getStatusColor(status) {
  switch (status) {
    case Constants.ORDER.STATUS.PENDING:
      return 'warning';
    case Constants.ORDER.STATUS.APPROVED:
      return 'success';
    case Constants.ORDER.STATUS.REJECTED:
    default:
      return 'default';
  }
}

function getMedicStatusColor(status) {
  switch (status) {
    case Constants.ORDER.STATUS.PENDING:
      return 'warning';
    case Constants.ORDER.STATUS.APPROVED:
      return 'success';
    case Constants.ORDER.STATUS.REJECTED:
    default:
      return 'default';
  }
}

const Services = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, filterBy, goToPage, isLoading, pagination, print, reload, approveItem, products, rejectItem, updateAuthCode, updateDocuments, updateMedicStatus } = useFetchTable();

  const onSelectItem = (item, action) => {
    setSelectedItem(item);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="sm"
        isOpen={!!selectedItem}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.APPROVE) return (
              <ModalApprove
                onClose={onClose}
                onApprove={()=> {
                  approveItem(selectedItem?.id);
                  closeModal();
                }}
              />
            );
            if (modalAction === MODAL_ACTION.REJECT) return (
              <ModalReject
                onClose={onClose}
                onReject={()=> {
                  rejectItem(selectedItem?.id);
                  closeModal();
                }}
              />
            );
          }}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
        products={products}
      />

      <Table aria-label="Servicios solicitados"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primary">Servicios solicitados</h3>
            <Button variant="bordered" onClick={() => print()}>
              Exportar
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>N°</TableColumn>
          <TableColumn>Fecha</TableColumn>
          <TableColumn>Hora</TableColumn>
          <TableColumn>Zona</TableColumn>
          <TableColumn>Teléfono</TableColumn>
          <TableColumn>Nombre del usuario</TableColumn>
          <TableColumn>Cédula</TableColumn>
          <TableColumn>Producto</TableColumn>
          <TableColumn>Servicio</TableColumn>
          <TableColumn># Autorización</TableColumn>
          <TableColumn>Confirmación</TableColumn>
          <TableColumn>Documentos</TableColumn>
          <TableColumn>Estatus</TableColumn>
          <TableColumn>Revisión Médica</TableColumn>
          <TableColumn>Cantidad disponible</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={data} emptyContent="No hay resultados">
          {(order) => {
            const statusColor = getStatusColor(order.status);
            const medicStatusColor = getMedicStatusColor(order.medic_status);
            const isUnlimited = order?.purchase_service?.uses_quantity === 0;
            // Para ocultar el popover mediante el dom
            const popAuthId = `pop-auth-${order.id}`;
            const popDocsId = `pop-docs-${order.id}`;
            const popMediId = `pop-medi-${order.id}`;

            return (
              <TableRow key={order.id}>
                <TableCell>{ String(order.id).padStart(4,'0') }</TableCell>
                <TableCell>{ moment(order.created_at).format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ moment(order.created_at).format('h:mmA') }</TableCell>
                <TableCell>{ order?.user?.zone }</TableCell>
                <TableCell>{ order?.user?.phone }</TableCell>
                <TableCell>{ order?.user?.fullName }</TableCell>
                <TableCell>{ order?.user?.dni }</TableCell>
                <TableCell>{ order?.purchase_service?.product?.name }</TableCell>
                <TableCell>{ order?.purchase_service?.service?.name }</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <Popover placement="bottom" showArrow backdrop="opaque">
                      <PopoverTrigger className="cursor-pointer">
                        <i id={popAuthId}>
                          <IconEdit size={18} className="text-primary" />
                        </i>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopAuthCode
                          order={order}
                          onSubmit={(form) => {
                            updateAuthCode(form);
                            document.getElementById(popAuthId)?.click();
                          }}
                        />
                      </PopoverContent>
                    </Popover>
                    <span>{ order?.auth_code }</span>
                  </div>
                </TableCell>
                <TableCell>{ order?.confirm_received ? 'Sí':'No' }</TableCell>
                <TableCell>
                  <Popover placement="bottom" showArrow backdrop="opaque">
                    <PopoverTrigger>
                      <span id={popDocsId} className="font-semibold text-primary cursor-pointer">
                        Ver
                      </span>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverDocuments
                        orderId={order.id}
                        user={order?.user?.owner || order?.user}
                        docs={order.documents}
                        onSubmit={(form) => {
                          updateDocuments(form);
                          document.getElementById(popDocsId)?.click();
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                </TableCell>
                <TableCell>
                  <Chip color={statusColor} variant="flat">{ order.status_text }</Chip>
                </TableCell>
                <TableCell>
                  <Popover placement="bottom" showArrow backdrop="opaque">
                    <PopoverTrigger>
                      <Chip id={popMediId} color={medicStatusColor} variant="flat" className="cursor-pointer">
                        { order.medic_status_text }
                      </Chip>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopMedicStatus
                        order={order}
                        onSubmit={(form) => {
                          updateMedicStatus(form);
                          document.getElementById(popMediId)?.click();
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                </TableCell>
                <TableCell>
                  { isUnlimited
                    ? 'Ilimitado'
                    : order?.purchase_service?.uses_available + '/' + order?.purchase_service?.uses_quantity
                  }
                </TableCell>
                <TableCell align="right">
                  {order.status === Constants.ORDER.STATUS.PENDING && (
                    <div className="relative flex justify-end items-center gap-2">
                      <Tooltip content="Aprobar">
                        <span className="text-success">
                          <IconThumbUp onClick={() => onSelectItem(order, MODAL_ACTION.APPROVE)} />
                        </span>
                      </Tooltip>
                      <Tooltip color="danger" content="Rechazar">
                        <span className="text-danger">
                          <IconThumbDown onClick={() => onSelectItem(order, MODAL_ACTION.REJECT)} />
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, products, resetFilter }) => {
  const initialFilter = {
    level_id: '',
    product_id: '',
    since: '',
    until: '',
    search: '',
    status: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Select
        label="Tipo de usuario"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        classNames={{ base: 'w-full sm:max-w-[10rem]', trigger: 'border-1' }}
        disallowEmptySelection={true}
        selectedKeys={[form.level_id]}
        onSelectionChange={v => onChange(v.currentKey, 'level_id')}
      >
        {level_ids.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            { item.label }
          </SelectItem>
        ))}
      </Select>
      <Input
        classNames={{
          base: 'w-full sm:max-w-[15rem]',
          inputWrapper: 'border-1 h-10',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder={`${+form.level_id === Constants.USER.LEVELS.OWNER ? 'Titular':'Beneficiario'}, Servicio o C.I.`}
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Select
        label="Estatus del servicio"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        classNames={{ base: 'w-full sm:max-w-[10rem]', trigger: 'border-1' }}
        disallowEmptySelection={true}
        selectedKeys={!!form.status ? [form.status]:[]}
        onSelectionChange={v => onChange(v.currentKey, 'status')}
      >
        {serviceStatuses.map((item) => (
          <SelectItem key={item.value} value={item.value}>
            { item.label }
          </SelectItem>
        ))}
      </Select>
      <Select
        label="Tipo de producto"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        classNames={{ base: 'w-full sm:max-w-[15rem]', trigger: 'border-1' }}
        disallowEmptySelection={true}
        selectedKeys={!!form.product_id ? [form.product_id]:[]}
        onSelectionChange={v => onChange(v.currentKey, 'product_id')}
      >
        {products.map((item) => (
          <SelectItem key={item.id} value={item.id} title={item.name}>
            { item.name }
          </SelectItem>
        ))}
      </Select>
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[10rem]',
          inputWrapper: 'border-1 h-10',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalApprove = ({ onClose, onApprove }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Realmente desea aprobar la solicitud?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="primary" onPress={onApprove}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const ModalReject = ({ onClose, onReject }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Realmente desea rechazar la solicitud?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="danger" onPress={onReject}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    level_id: '',
    product_id: '',
    search: '',
    status: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await OrdersService.admin.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const getProducts = async () => {
    try {
      const response = await ProductsService.admin.findAll(filters);
      setProducts(response.data);

    } catch (error) {
      setProducts([]);
      onError(String(error));
    }
  }

  const print = async () => {
    setCanFetch(false);
    try {
      const res = await OrdersService.admin.download(filters);
      const el = document.createElement('a');
      el.href = fromStorage(res.url);
      el.download = 'Pagos';
      el.target = '_blank';
      el.click();
      el.remove();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateAuthCode = async (form) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await OrdersService.admin.updateAuthCode(form);
      toast.success('Autorización actualiza con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateDocuments = async (form) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await OrdersService.admin.updateDocuments(form);
      toast.success('Documentos actualizados con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateMedicStatus = async (form) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await OrdersService.admin.updateMedicStatus(form);
      toast.success('Estatus médico actualizado con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const approveItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const data = { id, status: Constants.ORDER.STATUS.APPROVED };
      await OrdersService.admin.update(data);
      toast.success('Solicitud aprobada con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const rejectItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const data = { id, status: Constants.ORDER.STATUS.REJECTED };
      await OrdersService.admin.update(data);
      toast.success('Solicitud rechazada con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    getProducts();
  }, []);

  return {
    approveItem,
    canResetFilter: canResetFilter(),
    data,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    print,
    products,
    rejectItem,
    reload,
    updateAuthCode,
    updateDocuments,
    updateMedicStatus,
  }
}

export default Services;
