import moment from 'moment';

export const objectToQueryParams = (obj) => {
  const queryParams = [];

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent((Array.isArray(obj[key]) ? JSON.stringify(obj[key]): obj[key]) || '');
      queryParams.push(`${encodedKey}=${encodedValue}`);
    }
  }

  return queryParams.join('&');
}

export const fromPhotos = (url) => {
  return process.env.REACT_APP_BASE_STORAGE + url;
}

export const fromStorage = fromPhotos;

export const clone = (data) => {
  return JSON.parse(JSON.stringify(data));
}

export const setMomentLocale = () => {
  moment.updateLocale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
  }
  );
}

export const createFormData = (data) => {
  var formdata = new FormData();
  for (var key in data) {
    if (Array.isArray(data[key])) {
      for (const _key in data[key]) {
        if (Array.isArray(data[key][_key])) {
          for (const i in data[key][_key]) {
            if (isObject(data[key][_key])) {
              for (const j of Object.keys(data[key][_key])) {
                formdata.append(key + '[' + _key + '][' + i + '].' + j, data[key][_key][i][j]);
              }
            } else {
              formdata.append(key + '[' + _key + '][' + i + ']', data[key][_key][i]);
            }
          }
        } else {
          if (isObject(data[key][_key])) {
            for (const i of Object.keys(data[key][_key])) {
              formdata.append(key + '[' + _key + '].' + i, data[key][_key][i]);
            }
          } else {
            formdata.append(key + '[' + _key + ']', data[key][_key]);
          }
        }
      }
    } else {
      formdata.append(key, data[key]);
    }
  }
  return formdata;
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
}

export const copy = (objectOrArray) => {
  return JSON.parse(JSON.stringify(objectOrArray));
}

const fillDecimal = (x) => String(x).replace(/((\.|,)\d)$/, (_, p1) => p1 + '0');

export const formatAmount = (amount, symbol, conversion = 1) => {
  if (isNaN(amount)) amount = 0;
  const formatted = Intl.NumberFormat('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount * conversion);
  const _amount = fillDecimal(formatted);
  return !!symbol ? `${symbol || ''} ${_amount}` : _amount;
}

export const autoFormatAmount = (amount, currency, conversion = 1) => {
  if (isNaN(amount)) amount = 0;
  conversion = currency?.is_local ? conversion : 1;
  const formatted = Intl.NumberFormat('es-VE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount * conversion);
  const _amount = fillDecimal(formatted);
  return !!currency?.symbol ? `${currency?.symbol || ''} ${_amount}` : _amount;
}

export const sanitizeToNumbers = (value = '') => {
  return (value.match(/\d+/g,'') || []).join('');
}

export const isObject = (value) => {
  return typeof value === 'object'
  && value !== null
  && !Array.isArray(value)
  && !(value instanceof RegExp)
  && !(value instanceof Date)
  && !(value instanceof Set)
  && !(value instanceof Map)
}